import React from 'react'
import {Box, Card, Flex, Image, Link as A, Text} from 'theme-ui'
import vladLight from '../assets/images/vlad-light.png'
import matt from '../assets/images/matt-dark.png'
import joelDark from '../assets/images/joel-dark.png'

const cards = [{
    image: matt,
    title: 'Matt\'s Roof Garden',
    link: "https://matt.roam.garden",
}, {
    image: vladLight,
    title: 'Vlad\'s Roam Garden',
    link: "https://vlad.roam.garden",
}, {
    image: joelDark,
    title: 'OASIS Lab working notes',
    link: "https://joelchan.roam.garden/",
}]

const Gallery = () => {
    return (
        <Box as="section" sx={styles.section}>
            <Flex sx={{
                justifyContent: "center",
                alignItems: "center",
                '@media (-webkit-min-device-pixel-ratio: 2) and (max-width: 1450px), (min-resolution: 192dpi) and (max-width: 1450px) ': {
                    flexDirection: 'column',
                },
            }}>
                {cards.map(({image, title, link}) => (
                    <Card sx={styles.card}>
                        <A target="_blank" rel="noopener noreferrer" href={link}>
                            <Image src={image}/>
                            <Text sx={{textAlign: "center", color: '#02073E'}}>{title}</Text>
                        </A>
                    </Card>
                ))}
            </Flex>
        </Box>
    )
}

export default Gallery

const styles = {
    section: {},
    card: {
        backgroundColor: "#F9FBFD",
        margin: "1.2em",
        flexShrink: "1.7",
        flexGrow: "1",
        transition: "flex-shrink 0.4s ease-in-out",
        "&:hover": {
            flexGrow: "1.9",
            flexShrink: "1",
            transition: "flex-shrink 0.6s ease-in-out",
        },
    },
}
