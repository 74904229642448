import React, {useState} from 'react'
import {IoIosAdd, IoIosRemove} from 'react-icons/io'

import {Box, Container, Heading, Link as A, Text} from 'theme-ui'
import {domain} from "../config"

const FAQ_TWO_DATA = {
    sectionTitle: {
        title: 'FAQ',
        text: 'Get your questions answered',
    },
    posts: [
        {
            status: true,
            title: 'What do you do with the original uploaded JSON?',
            content: "It's cached for up to 2 days and then discarded. " +
                "This is mainly driven by the minimal automated expiration policy in AWS S3. " +
                "We don't use data in your graph in any way besides what's required to build the site with " +
                "settings you've specified.",
        },
        {
            status: false,
            title: 'Can I customize how my garden looks?',
            preview: "Yes you can upload custom CSS for your garden",
            get content() {
                return <>{this.preview}. <A href="https://matt.roam.garden/">Matt's garden </A>
                    can serve as an inspiration and great example of customization</>
            },
        },
        {
            status: false,
            title: "How does filtering work?",
            content: "All pages are private by default." +
                "\nYou can make a page public by marking it with a special tag." +
                "\nWithin public pages you can additionally mark certain blocks as private." +
                "\nBlock references and embeds on public pages would be resolved and included." +
                "\nFor any private page that was referenced in the public page an \"Orphan\" page would be created. " +
                "Such a page would collect backlinks to a private page from the public pages without revealing the " +
                "content of the private page.",
        },
        {
            status: false,
            title: 'How is my Roam Garden address determined?',
            content: `It's based on your username. It will look like '<username>.${domain}'`,
        },
        {
            status: false,
            title: 'Can I use a custom domain for my Garden?',
            preview: "Not natively, but we partner with",
            content: <>Not natively, but we partner with <A href="https://cloak.ist/">Cloakist</A> to
                provide this functionality while we're developing native support.
                Go <A href="https://cloak.ist/start">here</A> to get started.</>,
        },
        // {
        //   status: false,
        //   title: 'Can I self-host?', /// is it really FAQ? good place to give credits to gatsby-theme-garden?
        //   content:
        //     "We make it easy to move to CometNine. Simply contact us and we'll move your hosting account from any other provider, regardless of the control panel. If at anytime your website is down for more than 0.1% result with proper investigation experiments.",
        // },
    ],
    button: {
        link: '#',
        label: 'Still Question? Contact us',
    },
}

const cutoffIdx = 65

/**
 *
 * @param {string} text
 * @returns {*}
 */
const abbreviatedText = text => text?.slice(0, cutoffIdx) + "..."

const FaqItem = ({title, preview, status, index, ...props}) => {
    const [active, setActive] = useState(status)
    const handleClick = () => {
        setActive(!active)
    }
    return (
        <Box
            sx={styles.item}
            className={`${active === true ? 'active' : ' '}`}
            onClick={handleClick}
        >
            <Heading as="h3">
                {title}
            </Heading>
            {active === false ? <Text as="p">{abbreviatedText(preview)}</Text> : null}
            {active && <Text as="p">{props.children}</Text>}

            <Box sx={styles.icon}>
                {!active && <IoIosAdd/>}
                {active && <IoIosRemove/>}
            </Box>
        </Box>
    )
}

const Faq = () => {
    const {sectionTitle, posts, button} = FAQ_TWO_DATA
    return (
        <Box sx={styles.section} id="faq">
            <Container>
                <Box sx={styles.sectionTitle}>
                    <Heading as="h3">{sectionTitle.title}</Heading>
                </Box>
                <Box>
                    {posts.map(({title, preview, content, status}, index) => (
                        <FaqItem
                            key={`faq-key-${index}`}
                            title={title}
                            preview={preview || content}
                            index={index}
                            status={status}>{content}</FaqItem>
                    ))}
                </Box>
                {/*<Box sx={styles.buttonWrap}>*/}
                {/*  <Link href={button.link}>{button.label}</Link>*/}
                {/*</Box>*/}
            </Container>
        </Box>
    )
}

export default Faq

const styles = {
    section: {
        pb: ['70px', null, null, null, '100px', null, '150px'],
        pt: ['40px', null, null, null, '75px'],
    },
    sectionTitle: {
        mb: ['0', null, null, null, '40px'],
        textAlign: 'center',
        p: {
            color: '#00A99D',
            fontSize: '16px',
        },
        h3: {
            color: '#0F2137',
            letterSpacing: '-0.5px',
            fontSize: '24px',
            fontWeight: 500,
        },
    },
    item: {
        display: 'flex',
        flexWrap: 'wrap',
        borderBottom: '1px solid #E5E5E5',
        px: ['15px', '40px'],
        py: '40px',
        cursor: 'pointer',
        transition: 'all 500ms ease',
        position: 'relative',
        '&.active': {
            borderColor: '#0F2137',
        },
        h3: {
            flex: ['0 0 100%', null, null, null, '0 0 50%'],
            color: '#0F2137',
            fontSize: ['16px', null, null, '18px', null, '20px'],
            fontWeight: 500,
            letterSpacing: '-0.5px',
            position: 'relative',
            pl: ['0', null, null, null, '35px'],
            lineHeight: 1.67,
            whiteSpace: ['normal', null, null, null, 'pre-line'],
            mb: ['10px', null, null, null, '0'],
            span: {
                position: ['relative', null, null, null, 'absolute'],
                top: 0,
                left: 0,
                mr: ['10px', null, null, null, '0'],
            },
        },
        p: {
            flex: ['0 0 100%', null, null, null, '0 0 50%'],
            color: '#343D48',
            fontSize: ['15px', null, null, '16px'],
            lineHeight: [1.87, 2],
            whiteSpace: "pre-wrap",
        },
    },
    buttonWrap: {
        textAlign: 'center',
        mt: '40px',
        a: {
            display: 'inline-flex',
            backgroundColor: '#ECF2F7',
            borderRadius: '5px',
            color: '#738295',
            fontSize: '16px',
            fontWeight: 700,
            padding: '10px 22px',
            transition: 'all 500ms ease',
            '&:hover': {
                backgroundColor: '#4F96FF',
                color: '#fff',
            },
        },
    },
    icon: {
        position: 'absolute',
        top: '45px',
        right: 0,
        svg: {
            fontSize: '24px',
            color: '#B1B8C0',
        },
    },
}
