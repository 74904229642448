import React from 'react'

import 'rc-drawer/assets/index.css'
import 'assets/css/react-slick.css'
import 'react-modal-video/css/modal-video.min.css'
import 'typeface-bree-serif'
import 'typeface-dm-sans'

import {StickyProvider} from 'contexts/app/app.provider'
import SEO from 'components/seo'
import Layout from '../components/layout'
import Banner from '../sections/banner'
import Features from '../sections/features'
import Faq from '../sections/faq'
import Gallery from '../sections/gallery'

import '../common/amplify-wrapper'

export default function IndexPage() {
    return (
        <StickyProvider>
            <Layout>
                <SEO
                    title="Roam Garden"
                    description="Create beautifully tended Digital Gardens (static sites) from your RoamResearch graph"
                />
                <Banner/>
                <Gallery/>
                <Features/>
                {/*<ProductFeature />*/}
                {/*<Services />*/}
                {/*<Pricing />*/}
                {/*<CustomerSupport />*/}
                {/*<Video />*/}
                {/*<Faq/>*/}
            </Layout>
        </StickyProvider>
    )
}
